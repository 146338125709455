"use client";

import { useEffect, useState } from "react";
import { PlaceholdersAndVanishInput } from "../ui/placeholders-and-vanish-input";
// import { QRCodeReader } from "./QRCodeReader";
// import Button from "./Button";
// import { QrCode } from "lucide-react";

const linkSeparator = "//revolut.me/";
const tagSeparator = "@";

export default function RevTagInput({
  placeHolders = [
    "Paste your @RevTag here",
    "Hey! You can send me money on Revolut by following this link: https://revolut.me/RevTag",
    "Paste your https://revolut.me/RevTag here",
    "Paste your RevTag here",
  ],
}: {
  placeHolders: string[];
}) {
  const [isValid, setIsValid] = useState<boolean>();
  const [revtag, setRevtag] = useState("");
  // const [viaQR, setViaQR] = useState(false);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    const { value } = e.currentTarget[0] as any;
    let revtag = "";

    revtag = value.includes(tagSeparator)
      ? value.substring(1)
      : value.includes(linkSeparator)
        ? value.split(linkSeparator)[1]
        : value;
    setRevtag(revtag);
  };

  useEffect(() => {
    if (!revtag) return;

    (async function () {
      const isRevTagValid = await fetch(`/api/revtag/${revtag}`).then(
        async (r) =>
          r
            .json()
            .then((d) => d)
            .catch(() => false),
      );

      setIsValid(isRevTagValid);
    })();
  }, [revtag]);

  useEffect(() => {
    if (typeof isValid === "undefined") return;

    alert(
      isValid
        ? `Goooooooo !!!  Revtag ${revtag} is valid !`
        : `Bad luck !!!  Revtag ${revtag} is not valid !`,
    );
  }, [isValid]);

  return (
    <div className="mx-auto flex max-w-2xl flex-col py-20">
      <div className="jusify-center relative flex  flex-col items-center justify-center">
        <PlaceholdersAndVanishInput
          name="revtag"
          onSubmit={onSubmit}
          prefixIcon={"👉"}
          placeholders={placeHolders}
        />
        {/* <span>{"|"}</span> */}

        {/* {!viaQR && (
          <Button className="relative size-[60px] rounded-xl flex-col" onClick={() => setViaQR((v) => !v)}>
            <QrCode className="absolute size-full scale-75 opacity-50" />
            {"Scan my RevTag"}
          </Button>
        )} */}
      </div>
      {/* {viaQR && (
        <QRCodeReader />
      )} */}
    </div>
  );
}

import NextImage, {
  type ImageProps as NextImageProps,
  type StaticImageData as NextStaticImageData,
} from "next/image";
import { cn } from "@/lib/utils";

type ServerImageProps = Omit<NextImageProps, "placeholder"> & {
  usePlaceholder?: boolean;
  sizes?: string;
  responsiveSizes?: {
    sm?: number;
    md?: number;
    lg?: number;
  };
  relative?: boolean;
  fallbackSrc?: string;
  isLoading?: boolean;
  postal?: boolean | "xs" | "sm" | "md" | "lg" | "xl" | "2xl";
};

// const shimmer = (w: number, h: number) => `
// <svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
//   <defs>
//     <linearGradient id="g">
//       <stop stop-color="#f3f3f3" offset="20%" />
//       <stop stop-color="#ecebeb" offset="50%" />
//       <stop stop-color="#f3f3f3" offset="70%" />
//     </linearGradient>
//   </defs>
//   <rect width="${w}" height="${h}" fill="#f3f3f3" />
//   <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
//   <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
// </svg>`;

// const toBase64 = (str: string) => Buffer.from(str).toString("base64");

export default function Image({
  usePlaceholder = true,
  responsiveSizes,
  fallbackSrc = "/path/to/fallback.jpg",
  className = "",
  priority,
  src,
  width,
  height,
  isLoading = false,
  relative,
  postal = false,
  sizes: defaultSizes,
  ...props
}: ServerImageProps) {
  if (!props.fill && !height && !width) props.fill = true;

  const sizes = responsiveSizes
    ? `(max-width: 640px) ${responsiveSizes.sm || 100}vw, 
       (max-width: 768px) ${responsiveSizes.md || 100}vw, 
       ${responsiveSizes.lg || 100}vw`
    : defaultSizes;

  const aspectRatio = width && height ? +width / +height : undefined;

  return (
    <div
      suppressHydrationWarning
      className={cn("relative overflow-hidden", {
        "postal-card": postal === true,
        "xs:postal-card": postal === "xs",
        "sm:postal-card": postal === "sm",
        "md:postal-card": postal === "md",
        "lg:postal-card": postal === "lg",
        "xl:postal-card": postal === "xl",
        "2xl:postal-card": postal === "2xl",
      })}
      style={{
        width,
        height,
        aspectRatio: aspectRatio ? `${aspectRatio}` : "auto",
      }}
    >
      <NextImage
        {...props}
        suppressHydrationWarning
        src={src || fallbackSrc}
        width={width}
        height={height}
        sizes={sizes}
        priority={priority}
        loading={priority ? undefined : "lazy"}
        className={cn(
          "transition-opacity duration-300",
          isLoading ? "opacity-0" : "opacity-100",
          {
            "!relative": relative,
          },
          "rounded-md shadow-sm",
          className,
        )}
        placeholder={usePlaceholder ? "blur" : "empty"}
        blurDataURL={"/images/logo.svg"}
        style={{
          objectFit: "cover",
          objectPosition: "center",
        }}
      />
    </div>
  );
  // return (
  //   <div
  //     suppressHydrationWarning
  //     className={cn("relative overflow-hidden", className, {
  //       "postal-card": postal === true,
  //       "xs:postal-card": postal === "xs",
  //       "sm:postal-card": postal === "sm",
  //       "md:postal-card": postal === "md",
  //       "lg:postal-card": postal === "lg",
  //       "xl:postal-card": postal === "xl",
  //       "2xl:postal-card": postal === "2xl",
  //     })}
  //     style={{
  //       width,
  //       height,
  //       aspectRatio: aspectRatio ? `${aspectRatio}` : "auto",
  //     }}
  //   >
  //     {usePlaceholder && isLoading && (
  //       <div
  //         className="bg-muted absolute inset-0 animate-pulse"
  //         style={{
  //           backgroundImage: `url("data:image/svg+xml;base64,${toBase64(shimmer(Number(width) || 700, Number(height) || 475))}")`,
  //           backgroundSize: "cover",
  //         }}
  //       />
  //     )}
  //     <NextImage
  //       {...props}
  //       suppressHydrationWarning
  //       src={src || fallbackSrc}
  //       width={width}
  //       height={height}
  //       sizes={sizes}
  //       priority={priority}
  //       loading={priority ? undefined : "lazy"}
  //       className={cn(
  //         "transition-opacity duration-300",
  //         isLoading ? "opacity-0" : "opacity-100",
  //         {
  //           "!relative": relative,
  //         },
  //         "rounded-md shadow-sm",
  //         className,
  //       )}
  //       placeholder="blur"
  //       blurDataURL={
  //         usePlaceholder
  //           ? `data:image/svg+xml;base64,${toBase64(shimmer(Number(width) || 700, Number(height) || 475))}`
  //           : undefined
  //       }
  //       style={{
  //         objectFit: "cover",
  //         objectPosition: "center",
  //       }}
  //     />
  //   </div>
  // );
}

export * from "next/image";

export type StaticImageData = NextStaticImageData & { alt?: string };
export type ObjectImageData = {
  src: string;
  alt: string;
  height?: number | `${number}`;
  width?: number | `${number}`;
};

export type NextImageData = StaticImageData | ObjectImageData;

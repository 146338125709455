"use client";
import { getStaggeredDelay } from "@/lib/set-staggered-delay";
import type { ComponentPropsWithoutRef } from "react";
import { cn } from "@/lib/utils";
import Counter from "@/atoms/counter";
import { Typography } from "../ui/typography";

export type StatCardProps = {
  icon: React.ReactNode;
  title: string;
  value: number;
  counterSuffix: string;
};

export function StatCard({ icon, title, value, counterSuffix }: StatCardProps) {
  return (
    <div className="group relative flex h-[90px] items-center rounded-xl border border-primary/40 bg-foreground/10 px-4 text-primary md:h-[120px]">
      {/* <span className="z-1 rounded-5 relative inline-grid size-[50px] flex-none place-items-center bg-primary  text-white">
        <span className="text-[1.875rem] text-white [transition:all_500ms_ease] group-hover:[transform:rotateY(180deg)] group-hover:[transition-delay:.1s]"> */}

      <span className="z-1 rounded-5 relative inline-grid size-[50px] flex-none place-items-center">
        <span className="text-[1.875rem] [transition:all_500ms_ease] group-hover:[transform:rotateY(180deg)] group-hover:[transition-delay:.1s]">
          {icon}
        </span>
      </span>
      <div className="rounded-5  bg-accent-100 p-30px dark:bg-accent-700 -ml-5 flex  min-h-[120px] flex-1 flex-col justify-center py-2.5  pl-10">
        <Typography variant={"h3"}>
          <Counter end={value} suffix={counterSuffix} />
          <span className="sr-only">Counter</span>
        </Typography>

        <Typography variant={"small"}>{title}</Typography>
      </div>
    </div>
  );
}

export type StatisticsSectionProps = ComponentPropsWithoutRef<"div"> & {
  statistics: StatCardProps[];
};

function Statistics({ className, statistics }: StatisticsSectionProps) {
  return (
    <div
      className={cn(
        "gap-4 md:gap-12 grid sm:grid-cols-2 lg:grid-cols-4 ",
        className,
      )}
    >
      {statistics.map((stat, index) => (
        <div
          key={index}
          data-aos="fade-up"
          data-aos-delay={getStaggeredDelay([200, 400, 600, 800], index)}
        >
          <StatCard {...stat} />
        </div>
      ))}
    </div>
  );
}

export default Statistics;

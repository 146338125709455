"use client";

import React, { useEffect, useRef, useState } from "react";
import { Typography } from "../ui/typography";

type CounterProps = {
  end: number;
  suffix?: string;
  duration?: number; // in seconds
  delay?: number; // in milliseconds
};

const Counter: React.FC<CounterProps> = ({
  end,
  suffix = "",
  duration = 2,
  delay = 0,
}) => {
  const [count, setCount] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const counterRef = useRef<HTMLSpanElement | null>(null);
  const intervalRef = useRef<number | null>(null);

  useEffect(() => {
    // Intersection Observer to detect if component is visible
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.5 },
    );

    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => {
      observer.disconnect();
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      const startTime = Date.now();
      const increment = end / ((duration * 1000) / 50); // 50ms interval for smooth animation

      setTimeout(() => {
        intervalRef.current = window.setInterval(() => {
          const timeElapsed = Date.now() - startTime;
          if (timeElapsed >= duration * 1000) {
            setCount(end);
            clearInterval(intervalRef.current as number);
          } else {
            setCount((prev) => Math.min(prev + increment, end));
          }
        }, 50);
      }, delay);

      return () => {
        clearInterval(intervalRef.current as number);
      };
    }
  }, [isVisible, end, duration, delay]);

  return (
    <Typography>
      <span ref={counterRef} />
      {/* {Math.round(count)} */}
      {new Intl.NumberFormat().format(Math.round(count))} {suffix}
    </Typography>
  );
};

export default Counter;

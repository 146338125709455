import {
  Link as NextIntlLink,
  type Link as OriginLinkProps,
} from "@/navigation";
import type { PropsWithChildren } from "react";

export enum LinkTargetProps {
  BLANK = "_blank",
  POPUP = "_popup",
}

type BaseLinkProps = typeof OriginLinkProps;

// Helper type to extract the href prop type from BaseLinkProps
type BaseHrefProp = BaseLinkProps extends { href: infer T } ? T : never;

// Define a custom type for the href prop
type CustomHref = BaseHrefProp | string;

// Create a new type that extends BaseLinkProps with a modified href prop
export type LinkProps = PropsWithChildren<{
  href: CustomHref;
}> &
  Omit<BaseLinkProps, "href"> & {
    className?: string;
    href: CustomHref;
    target?: LinkTargetProps;
  };

export default function Link({ href, ...props }: LinkProps) {
  return <NextIntlLink href={href as BaseHrefProp} {...props} />;
}
